import { debounce } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import {
  Button,
  ISelectOption,
  Input,
  Notification,
  useData,
} from 'react-ui-kit-exante';
import { useDebounce } from 'use-debounce';

import {
  FORM_DELAY,
  Locales,
  Notifications,
} from '../../../../constants/common';
import { ServerRequestStatuses } from '../../../../services/api/Api.consts';
import { TEventForm } from '../../../../services/shaper/events/events.types';
import { MailingsService } from '../../../../services/shaper/mailings';
import { TemplatesService } from '../../../../services/shaper/templates';
import {
  DebugNotificationData,
  DebugTemplateInfo,
  DebugNotificationParams,
} from '../../../../services/shaper/templates/templates.types';
import { FormInputContainer } from '../../../Form/FormInputContainer/FormInputContainer';
import { FormMultiSelectContainer } from '../../../Form/FormMultiSelectContainer/FormMultiSelectContainer';
import { HtmlEditor } from '../../../HtmlEditor/HtmlEditor';
import { TranslationInfo } from '../../../TranslationInfo';
import { AddBranding } from '../../AddBranding';
import { TemplateCheckboxes } from '../../TemplateCheckboxes';
import { Editor } from '../Editor';

import {
  StyledTab,
  StyledTabContainer,
  StyledContentContainer,
  StyledEmailTemplateWrapper,
  StyledEmailEditorWrapper,
  StyledSendEmailForm,
  StyledEmailTemplateSettings,
} from './Email.styled';
import { EmailProps } from './Email.types';
import { EmailViewer } from './EmailViewer';
import { Metadata } from './Metadata';

const defaultTabs = ['Editor', 'Code Editor', 'Metadata'];

export const Email: FC<EmailProps> = ({
  translations,
  trans,
  isDefaultTemplate,
  isEditable = true,
  brandingsUsed = [],
  type = '',
}) => {
  const [locale, setLocale] = useState(Locales.en);
  const [activeTab, setActiveTab] = useState(0);
  const { getValues } = useFormContext<TEventForm>();
  const [header, setHeader] = useState('');
  const [footer, setFooter] = useState('');

  const onTabClick = (tabIdx: number) => {
    setActiveTab(tabIdx);
  };

  const getMailings = useCallback(async () => {
    try {
      return await MailingsService.getMailingListsForTemplate();
    } catch (error: any) {
      Notification.error(error?.message);
    }
    return [];
  }, []);

  const getJoinedData = () => {
    let resultContent = getValues('template.content');
    if (header) {
      resultContent = `${header}${resultContent}`;
    }
    if (footer) {
      resultContent = `${resultContent}${footer}`;
    }
    return resultContent;
  };

  const mailingsData = useData<ISelectOption[]>({
    onFetch: getMailings,
  });

  const onDebug = useCallback(async () => {
    try {
      const params: DebugNotificationParams = {
        debug: true,
        locale,
        template_name: getValues('template.name'),
        event: getValues('template.event_name'),
        to: getValues('template.sendTo'),
      };
      const reqData: DebugNotificationData = {
        content: getJoinedData(),
        subject: getValues('template.subject'),
        action: getValues('template.action'),
      };

      return await TemplatesService.debugNotification(params, reqData);
    } catch (error: any) {
      Notification.error(error?.message);
    }
    return null;
  }, [locale, header, footer]);

  const debugData = useData<DebugTemplateInfo | null>({
    onFetch: onDebug,
    loading: true,
  });

  const onChangeLocale = (lang: Locales) => {
    setLocale(lang);
  };

  const onHeaderFooterRemoved = (headerStr: string, footerStr: string) => {
    setHeader(headerStr);
    setFooter(footerStr);
  };

  const error = debugData.data?.error
    ? {
        lineno: debugData.data?.lineno || 0,
        error: debugData.data?.error,
      }
    : null;

  const isChanged = useWatch({
    name: ['template.content', 'template.subject'],
  });
  const [value] = useDebounce(isChanged, FORM_DELAY);

  useEffect(() => {
    debugData.fetchData();
  }, [value, locale]);

  useEffect(() => {
    mailingsData.fetchData();
  }, []);

  const mailingOptions = mailingsData?.data?.length
    ? mailingsData?.data.filter((mailing) => mailing.value)
    : [];

  // if !has_new_editor, hide first tab
  const withNewEditor =
    getValues('template.has_new_editor') && !isDefaultTemplate;
  useEffect(() => {
    setActiveTab(withNewEditor ? 0 : 1);
  }, []);

  // #region Send Test Email
  const [isMailSending, setIsMailSending] = useState(false);
  const formContext = useFormContext<TEventForm>();

  const {
    field: { value: sendTo },
  } = useController({
    control: formContext.control,
    name: 'template.sendTo',
  });

  const onSendMail = useCallback(async () => {
    const successMessage = `${Notifications.TemplateType} is ${Notifications.SuccessSent}`;
    if (debugData.data) {
      try {
        setIsMailSending(true);
        const response = await TemplatesService.sendNotification(
          debugData.data.meta?.req_json,
        );
        if (response.message === ServerRequestStatuses.Success) {
          Notification.success({ title: successMessage });
        }
      } catch (sendError: any) {
        Notification.error(sendError?.message);
      } finally {
        setIsMailSending(false);
      }
    }
  }, []);

  const onSendClick = () => {
    onSendMail();
  };

  const debouncedSave = useCallback(
    debounce(() => debugData.fetchData(), FORM_DELAY),
    [],
  );

  useEffect(() => {
    if (sendTo) {
      debouncedSave();
    }
  }, [sendTo]);

  const sendButtonDisabled =
    !sendTo || isMailSending || !debugData.data?.content || !isEditable;
  // #endregion

  return (
    <>
      <StyledEmailTemplateWrapper className="EmailWrapper">
        <StyledEmailEditorWrapper className="EmailEditorWrapper">
          <StyledTabContainer className="EmailTabContainer">
            {defaultTabs.map((tab, index) => {
              if (!withNewEditor && tab === 'Editor') {
                return null;
              }
              return (
                <StyledTab
                  key={tab}
                  isActive={index === activeTab}
                  onClick={() => onTabClick(index)}
                >
                  {tab}
                </StyledTab>
              );
            })}
          </StyledTabContainer>
          <StyledContentContainer className="EmailContentContainer">
            {activeTab === 0 && (
              <HtmlEditor
                name="template.content"
                error={error}
                disabled={!isEditable}
                onHeaderFooterRemoved={onHeaderFooterRemoved}
              />
            )}
            {activeTab === 1 && (
              <Editor
                name="template.content"
                error={error}
                disabled={!isEditable}
              />
            )}
            {activeTab === 2 && (
              <Metadata
                meta={debugData.data?.meta}
                isLoading={debugData.isLoading}
              />
            )}
          </StyledContentContainer>
        </StyledEmailEditorWrapper>

        <EmailViewer
          isLoading={debugData.isLoading}
          info={debugData.data}
          isEditable={isEditable}
        />
      </StyledEmailTemplateWrapper>
      <>
        {translations && (
          <TranslationInfo
            translations={translations}
            onClickHandler={onChangeLocale}
            selectedLocale={locale}
            disabled={debugData.isLoading || !isEditable}
            translationUuid={trans?.uuid}
          />
        )}

        <StyledEmailTemplateSettings>
          {!isDefaultTemplate && (
            <FormMultiSelectContainer
              label="Mailing lists"
              name="template.mailing_lists"
              options={mailingOptions}
              disabled={!mailingsData?.data?.length || !isEditable}
            />
          )}
          <AddBranding isEditable={isEditable} brandingsUsed={brandingsUsed} />
        </StyledEmailTemplateSettings>

        <TemplateCheckboxes isEditable type={type} />

        <StyledSendEmailForm>
          <Input
            label="Subject"
            value={debugData.data?.subject || ''}
            disabled
            fullWidth
          />
          <FormInputContainer
            label="User email"
            name="template.sendTo"
            type="text"
            readOnly={!isEditable}
          />
          <div className="SendEmailFormButton">
            <Button
              data-test-id="notifications-module__email_button--send"
              disabled={sendButtonDisabled}
              onClick={onSendClick}
              className="SendEmailFormButton"
              type="button"
            >
              Send
            </Button>
          </div>
        </StyledSendEmailForm>
      </>
    </>
  );
};
