import { uniqueId } from 'lodash';
import { useMemo } from 'react';
import { UploadIcon } from 'react-ui-kit-exante';

import { StyledFileInput } from './FileInput.styled';
import { TFileInputProps } from './FileInput.types';

export const FileInput = ({
  accept = '*',
  label = 'Upload file',
  multiple = false,
  onInputFile,
}: TFileInputProps) => {
  const id = useMemo(() => uniqueId('file-input-'), []);

  return (
    <StyledFileInput htmlFor={id}>
      <input
        accept={accept}
        hidden
        id={id}
        multiple={multiple}
        onChange={onInputFile}
        type="file"
      />

      <UploadIcon size={24} aria-label={label} title={label} />
    </StyledFileInput>
  );
};
