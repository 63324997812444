import { styled } from 'react-ui-kit-exante';

export const StyledFileInput = styled('label')(({ theme }) => ({
  color: theme?.color?.typo?.secondary,
  display: 'block',
  height: 24,
  cursor: 'pointer',

  ':hover': {
    color: theme?.color?.typo?.promo,
  },
}));
