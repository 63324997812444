import {
  TemplateFormType,
  TemplateInfo,
} from '../../../services/shaper/templates/templates.types';

export function getTemplateForm(
  type: string,
  formData?: TemplateInfo | null,
  eventName = '',
): TemplateFormType {
  return {
    id: formData?.id ? String(formData?.id) : '',
    created: formData?.created || '',
    event_script: formData?.event_script || '',
    event_name: formData?.event_name || eventName,
    description: formData?.description || '',
    name: formData?.name || '',
    base: formData?.base || null, // backend specific
    branding: formData?.branding || '',
    brandings: formData?.brandings || [],
    content: formData?.content || '',
    tags: formData?.tags || [],
    is_muted: formData?.is_muted || false,
    is_draft: formData?.is_draft || false,
    is_sms_as_fallback: formData?.is_sms_as_fallback || false,
    subject: formData?.subject || '',
    action: formData?.action || '',
    change_comment: '',
    sendTo: '',
    mailing_lists:
      formData?.mailing_lists.map((i) => i.name || String(i.id)) || [],
    type: formData?.type || type,
    has_new_editor:
      typeof formData?.has_new_editor === 'boolean'
        ? formData.has_new_editor
        : true,
  };
}
