import { ISelectOption } from 'react-ui-kit-exante';

import { TCommonParams } from '../../../types/api/types';
import { parseErrors } from '../../../utils/parseErrors';
import { getSortingParam } from '../../api/Api.helpers';

import {
  getMailingListsReq,
  getMailingReq,
  createMailingReq,
  updateMailingReq,
} from './mailings.requests';
import { Mailing, MailingFormType, MailingListsState } from './mailings.types';

export const getMailingLists = async (
  params: TCommonParams,
): Promise<MailingListsState> => {
  try {
    const response = await getMailingListsReq({
      limit: params.limit,
      offset: params.skip,
      ...getSortingParam(params),
    });
    return {
      result: response?.data?.results,
      total: response?.data?.count,
    };
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getMailingListsForTemplate = async (): Promise<
  ISelectOption[]
> => {
  try {
    const response = await getMailingListsReq();
    return response?.data?.results.map(({ name }) => ({
      label: name,
      value: name,
    }));
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getMailing = async (id: string): Promise<Mailing> => {
  try {
    const response = await getMailingReq(id);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const createMailing = async (
  reqData: MailingFormType,
): Promise<Mailing> => {
  try {
    const { data } = await createMailingReq(reqData);
    return data;
  } catch (error: any) {
    if (error?.response?.data) {
      throw new Error(parseErrors(error?.response.data));
    } else {
      throw new Error(error);
    }
  }
};

export const updateMailing = async (
  id: string,
  reqData: MailingFormType,
): Promise<Mailing> => {
  try {
    const { data } = await updateMailingReq(id, reqData);
    return data;
  } catch (error: any) {
    if (error?.response?.data) {
      throw new Error(parseErrors(error?.response.data));
    } else {
      throw new Error(error);
    }
  }
};
