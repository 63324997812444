import { IColumn } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from '../../constants/common';
import { TEvent } from '../../services/shaper/events/events.types';
import { CellValue } from '../../types/table';
import { formatDateWithTime } from '../../utils/date';

export const hiddenColumns = ['Event Id'];

export function getColumns(): IColumn<TEvent>[] {
  return [
    { Header: 'Event Id', accessor: 'event_id', disableFilters: true },
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 80,
      disableFilters: true,
    },
    {
      Header: 'Event',
      accessor: 'event_name',
      minWidth: 80,
      disableFilters: true,
    },
    {
      Header: 'Tags',
      accessor: 'event_tags',
      disableFilters: true,
      minWidth: 60,
      disableSortBy: true,
    },
    {
      Header: 'Brandings',
      accessor: 'brandings',
      disableFilters: true,
      minWidth: 60,
      disableSortBy: true,
    },
    {
      Header: 'Group',
      accessor: 'event_group',
      disableFilters: true,
      disableSortBy: true,
      minWidth: 80,
    },
    {
      Header: 'Created date',
      accessor: 'created',
      disableFilters: true,
      minWidth: 120,
      Cell: ({ row: { values } }: CellValue<TEvent>) => {
        return values.created
          ? formatDateWithTime(values.created)
          : DEFAULT_EMPTY_PLACEHOLDER;
      },
    },
    {
      Header: 'Modified date',
      accessor: 'modified',
      disableFilters: true,
      minWidth: 120,
      Cell: ({ row: { values } }: CellValue<TEvent>) => {
        return values.modified
          ? formatDateWithTime(values.modified)
          : DEFAULT_EMPTY_PLACEHOLDER;
      },
    },
    {
      Header: 'Channels',
      accessor: 'type',
      disableSortBy: true,
      disableFilters: true,
      minWidth: 120,
    },
  ];
}
