import { FC, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { ChannelType } from '../../../constants/common';
import { TEventForm } from '../../../services/shaper/events/events.types';

import { Email } from './Email';
import { Phone } from './Phone/Phone';
import { defaultTranslations } from './TemplateForm.consts';
import { getTemplateForm } from './TemplateForm.helpers';
import { TTemplateFormProps } from './TemplateForm.types';

export const TemplateForm: FC<TTemplateFormProps> = ({
  templateData,
  id,
  type,
  brandingsUsed,
}) => {
  const formContext = useFormContext<TEventForm>();

  const { getValues, reset } = formContext;

  useEffect(() => {
    const defaultTemplatesValues = getTemplateForm(
      type,
      templateData,
      getValues('event.name'),
    );
    reset({ ...getValues(), template: defaultTemplatesValues });
  }, [templateData]);

  const translationsData = useMemo(
    () =>
      templateData?.translations_ratio.filter((i) => i).length
        ? templateData?.translations_ratio
        : defaultTranslations,
    [templateData?.translations_ratio],
  );

  const template = getValues('template');

  const isTemplateEditable: boolean = !id || !!templateData?.edit;

  const getEditor = () => {
    if (type === ChannelType.Email) {
      return (
        <Email
          translations={translationsData}
          trans={templateData?.trans}
          isEditable={isTemplateEditable}
          brandingsUsed={brandingsUsed}
          type={type}
        />
      );
    }
    if (type === ChannelType.Phone) {
      return (
        <Phone
          translations={translationsData}
          trans={templateData?.trans}
          brandingsUsed={brandingsUsed}
          isEditable={isTemplateEditable}
          type={type}
        />
      );
    }
    return (
      <Email
        translations={translationsData}
        trans={templateData?.trans}
        brandingsUsed={brandingsUsed}
        isDefaultTemplate
        isEditable={isTemplateEditable}
        type={type}
      />
    );
  };
  if (!template) {
    return null;
  }

  return <>{getEditor()}</>;
};
