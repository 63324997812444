import { FilterValue } from 'react-ui-kit-exante';

import { ChannelType } from '../../../constants/common';
import { transformVariantsToSelectOptions } from '../../../utils/transformVariantsToSelectOptions';

export type FiltersState = {
  [key in FilterType]?: string[];
};

type GetAdditionalFilters = {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
  filterOptions?: FiltersState;
};

export enum FilterType {
  Search = 'search',
  EventName = 'name__icontains',
  Branding = 'templates__branding',
  EventTagName = 'tags__name',
  Type = 'templates__type',
  TemplatesIdIn = 'templates__id__in',
  GroupIn = 'group__in',
}

export type FiltersParams = {
  [FilterType.EventName]: string[];
  [FilterType.Branding]: string[];
  [FilterType.EventTagName]: string[];
  [FilterType.Type]: string;
};

export const defaultFilterState = {
  [FilterType.Branding]: [],
  [FilterType.EventTagName]: [],
  [FilterType.Type]: [],
  [FilterType.TemplatesIdIn]: [],
  [FilterType.GroupIn]: [],
};

export const typeVariants = [ChannelType.Phone, ChannelType.Email];

export const getDefaultFilters = () => ({});

export function getAdditionalFilters({
  onFilter,
  onRemove,
  filterOptions,
}: GetAdditionalFilters) {
  return [
    {
      Header: 'Context search',
      accessor: FilterType.Search,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Event name',
      accessor: FilterType.EventName,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Tags',
      accessor: FilterType.EventTagName,
      filterOptions: transformVariantsToSelectOptions(
        filterOptions?.tags__name,
      ),
      type: 'select',
      onFilter,
      onRemove,
    },
    {
      Header: 'Templates Branding',
      accessor: FilterType.Branding,
      filterOptions: transformVariantsToSelectOptions(
        filterOptions?.templates__branding,
      ),
      type: 'select',
      onFilter,
      onRemove,
    },
    {
      Header: 'Templates Type',
      accessor: FilterType.Type,
      filterOptions: transformVariantsToSelectOptions(typeVariants),
      type: 'select',
      onFilter,
      onRemove,
    },
    {
      Header: 'Group',
      accessor: FilterType.GroupIn,
      type: 'textInput',
      onFilter,
      onRemove,
    },
  ];
}
