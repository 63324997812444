import { FC } from 'react';
import { InfoIcon, Tooltip } from 'react-ui-kit-exante';

import { TooltipTexts } from '../../../../../constants/common';
import { FormInputContainer } from '../../../../Form/FormInputContainer/FormInputContainer';
import { TemplatePreview } from '../../../../TemplatePreview/TemplatePreview';

import { StyledEmailViewer, StyledViewerHeader } from './EmailViewer.styled';
import { EmailViewerProps } from './EmailViewer.types';

export const EmailViewer: FC<EmailViewerProps> = ({
  isLoading,
  info = null,
  isEditable,
}) => {
  return (
    <StyledEmailViewer className="EmailViewer">
      <StyledViewerHeader className="ViewerHeader">
        <FormInputContainer
          label="Subject"
          name="template.subject"
          type="text"
          readOnly={!isEditable}
        />
        <Tooltip title={TooltipTexts.SUBJECT_HINT}>
          <InfoIcon />
        </Tooltip>
      </StyledViewerHeader>

      <TemplatePreview isLoading={isLoading} info={info} />
    </StyledEmailViewer>
  );
};
